import $ from "jquery";

$( document ).ready(function() {

    //check for sections, and if full width text is the first...if so -> add margin

    var counter = 0
    $('section').each(function() {
       counter++;
       if (counter == 1 && $(this).hasClass("full_width_text") ) {
           $(this).css({ marginTop: '100px'})
       }

       if (counter > 1) {
           return;
       }
    });



    $('.copy-to-clipboard-link').on("click", function(){
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($("#hidden-copy-link").val()).select();
        document.execCommand("copy");
        $temp.remove();
    });

    $('.copy-to-clipboard-playlist-link').on("click", function(){
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val($("#hidden-copy-playlist-link").val()).select();
        document.execCommand("copy");
        $temp.remove();
    });

    $('*[class^="copy-to-clipboard-"]').on("click", function(){
        var $temp = $("<input>");
        var thisClassname = $(this).attr('class');
        var getTargetIdentifier = thisClassname.split("_")[1]
        $("body").append($temp);


        $temp.val($("#hidden-copy-link-"+getTargetIdentifier).val()).select();
        document.execCommand("copy");
        $temp.remove();
    });




    $(".toggle-pro-section").on("click", function() {
        var targetId = $(this).data('toggle-target')

        console.log(targetId)
        $(".pro-track-"+targetId).toggle()

    });

    $(".toggle-schedule-day").on("click", function() {
        var targetId = $(this).data('toggle-target')

        var iconElement = $(this).find("i")

        // Check if the element has the class "fa-plus-circle"
        if (iconElement.hasClass('fa-plus-circle')) {
            // Replace "fa-plus-circle" with "fa-minus-circle"
            iconElement.removeClass('fa-plus-circle').addClass('fa-minus-circle');
        } else if (iconElement.hasClass('fa-minus-circle')) {
            // Replace "fa-minus-circle" with "fa-plus-circle"
            iconElement.removeClass('fa-minus-circle').addClass('fa-plus-circle');
        }


        $(".schedule-day-"+targetId).toggle()
    });



    $('.support-box, .wide-support-box').on("click", function(){
        var link = $(this).data('link')
        if ( link != null) {

            try {
                const link_url = new URL(link);

                if ( link_url.hostname == "teamicg.online" || link_url.hostname == "stage.teamicg.online") {
                    window.location = link
                } else {
                    window.open(link,"_blank");
                }
            } catch (e) {
                window.location = link
            }


        }
    });





});



